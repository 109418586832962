import Swal from 'sweetalert2';

export const toastr = (title: string, icon: any) => {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    showCloseButton: true,
    timerProgressBar: true,
    focusCancel: true,
    didOpen: toast => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });
  Toast.fire({
    icon,
    title: title,
    timer: 3000,
    timerProgressBar: true,
    heightAuto: true,
    grow: 'row',
  });
};
export const confirmDialog = (title: string) => {
  return new Promise(resolve => {
    Swal.fire({
      title: '',
      text: title,
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      confirmButtonColor: '#002e6e',
      // denyButtonText: `Don't save`,
    })
      .then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          resolve(true);
          // Swal.fire('Saved!', '', 'success');
        } else if (result.isDenied) {
          resolve(false);
          // Swal.fire('Changes are not saved', '', 'info');
        } else {
          resolve(false);
        }
      })
      .catch(() => resolve(false));
  });
};

export const confirmSweetRequest = (title: string) => {
  return new Promise(resolve => {
    Swal.fire({
      title: title,
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      confirmButtonColor: '#3299cc',
      showLoaderOnConfirm: true,
      preConfirm: response => {
        return resolve(response);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    })
      .then(result => {
        if (result.isConfirmed) {
          resolve(true);
          // Swal.fire('Saved!', '', 'success');
        } else if (result.isDenied) {
          resolve(false);
          // Swal.fire('Changes are not saved', '', 'info');
        } else {
          resolve(false);
        }
      })
      .catch(() => resolve(false));
  });
};

export const showSweetProcessing = () => {
  return new Promise(resolve => {
    Swal.fire({
      title: 'Processing...',
      html: '',
      timerProgressBar: true,
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton() as HTMLButtonElement);
      },
    });
    resolve(Swal);
  });
};
