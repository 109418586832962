import { api } from '@/utils/axiosInterceptor';
import { ACTION } from '@/types/interfaces';
import { handleErrors } from '@/utils/handleError';
const HOME_API_URL = process.env.BACKEND_API_URL + 'home/';
//eslint-disable-next-line no-unused-vars
export async function ADD_CONTACT_US(action: ACTION): Promise<unknown> {
  const { payload } = action;
  const res: ReturnType<any> = await api(`${HOME_API_URL}contact-us`, 'POST', {
    data: payload,
  });
  if (res.status) {
    return res;
  } else {
    if (res.errors) return handleErrors(res.errors);
  }
}
