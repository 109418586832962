import { api } from '@/utils/axiosInterceptor';
import { ACTION } from '@/types/interfaces';
import { toastr } from '@/utils/sweetalert';
import { handleErrors } from '@/utils/handleError';
const ADS_API_URL = process.env.BACKEND_API_URL + 'ads/';
const HOME_API_URL = process.env.BACKEND_API_URL + 'home/';

/* Get Error Logs */
//eslint-disable-next-line no-unused-vars

export async function getTopups(): Promise<unknown> {
  const res: ReturnType<any> = await api(`${HOME_API_URL}get-topups`, 'POST', {});
  if (res.status) {
    return res;
  } else {
    // Handle Errors
    if (res.errors) return handleErrors(res.errors);
  }
}

export async function getAds(action: ACTION): Promise<unknown> {
  const { payload } = action;
  const res: ReturnType<any> = await api(`${ADS_API_URL}list`, 'POST', { data: payload });
  if (res.status) {
    return res;
  } else {
    // Handle Errors
    if (res.errors) return handleErrors(res.errors);
  }
}

/* Create Ads Data */
export async function addAds(action: ACTION): Promise<unknown> {
  const { payload } = action;
  const res: ReturnType<any> = await api(`${ADS_API_URL}add`, 'POST', {
    data: payload,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  if (res.status) {
    toastr('Ads Added Successfully', 'success');
    return res;
  } else {
    // Handle Errors
    if (res.errors) return handleErrors(res.errors);
  }
}

export async function razorpayOrders(action: ACTION): Promise<unknown> {
  const { payload } = action;
  const res: ReturnType<any> = await api(`${ADS_API_URL}razorpay-orders`, 'POST', { data: payload });
  if (res.status) {
    return res;
  } else {
    // Handle Errors
    if (res.errors) return handleErrors(res.errors);
  }
}
