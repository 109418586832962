import React from 'react';
import { ThreeDots, Bars } from 'react-loader-spinner';

interface PROPS {
  color?: string;
}
function ButtonLoader(props?: PROPS) {
  return (
    <div className="d-flex justify-content-center">
      <ThreeDots
        height="30"
        width="30"
        color={props?.color || '#FFFFFF'}
        ariaLabel="three-circles-rotating"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
}

export function BarLoader(props?: PROPS) {
  return (
    <div className="d-flex justify-content-center">
      <Bars
        height="30"
        width="30"
        color={props?.color || '#FFFFFF'}
        ariaLabel="three-circles-rotating"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
}

export function SuspenseLoader(props?: PROPS) {
  return (
    <div className="d-flex justify-content-center" style={{ marginTop: '5%' }}>
      <ThreeDots
        height="30"
        width="50"
        color={props?.color || '#FFFFFF'}
        ariaLabel="three-circles-rotating"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
}

export function SimpleLoader() {
  return (
    <div className="preloader">
      <div className="loading">
        <img loading="lazy" src="/assets/img/loader.gif" alt="Logo" />
      </div>
    </div>
  );
}
export const useLoading = () => {
  return { ButtonLoader, SimpleLoader };
};
