import { HANDLE_ERRORS } from '@/types/interfaces';

import { logout } from './helpers';
import { toastr } from './sweetalert';

export const handleErrors = (errors: HANDLE_ERRORS) => {
  let msg = '';
  if (errors) {
    if (typeof errors == 'object' && Object.keys(errors)?.length) {
      Object.entries(errors).map(([, value]) => {
        msg += value + '\n';
      });
    } else {
      if (typeof errors == 'string') {
        msg = errors;
        if (msg === 'Invalid auth token') {
          logout();
        }
      }
    }
  }
  toastr(msg, 'error');
};
