import React, { useContext, useCallback, useEffect } from 'react';
import Cookies from 'js-cookie';

import { api } from '@/utils/axiosInterceptor';
import { KEYPAIR } from '@/types/interfaces';

import { useCommonReducer } from './reducer';

// import { reducer, initialState } from './reducer';

interface Props {
  children: JSX.Element | JSX.Element[];
}

interface CONTEXTVALUE {
  state: any;
  dispatch: React.Dispatch<KEYPAIR>;
  getUserData: () => Promise<boolean>;
}

const AppContext = React.createContext<CONTEXTVALUE | null>(null);
export const AppProvider = ({ children }: Props) => {
  const { state, dispatch } = useCommonReducer();

  const getUserData = useCallback(async () => {
    try {
      const res: ReturnType<any> = await api(`${process.env.BACKEND_API_URL}auth/get-user-details`, 'GET');
      if (res.status) {
        const resData: any = res.data;
        dispatch({ user: resData });
        return true;
      }
      return false;
    } catch (error: any) {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const init = useCallback(() => {
    const token = Cookies.get('token') || '';
    if (token) getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value: CONTEXTVALUE = { state, dispatch, getUserData };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export function useApp() {
  const context = useContext(AppContext);
  if (context === null) {
    throw new Error('useApp must be used within an AppProvider');
  }
  return context;
}
export { useRequest } from './request';
export { useLoading } from './Loader';
