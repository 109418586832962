import { api } from '@/utils/axiosInterceptor';
import { handleErrors } from '@/utils/handleError';
const API_URL = process.env.BACKEND_API_URL + 'credits/';
/* Get Front Page */

//eslint-disable-next-line no-unused-vars
export async function getCredits(): Promise<unknown> {
  // const { payload } = action;
  const res: ReturnType<any> = await api(`${API_URL}get`, 'POST', {});
  if (res.status) {
    return res;
  } else {
    // Handle Errors
    if (res.errors) return handleErrors(res.errors);
  }
}

export async function getTopRateListAndBlog(): Promise<unknown> {
  const res: ReturnType<any> = await fetch(`${process.env.BACKEND_URL}/v1/front/api/home/list-data`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (!res.ok) {
    const errorText = await res.text();
    throw new Error(`HTTP error! status: ${res.status}, response: ${errorText}`);
  }
  return res?.json();
}
