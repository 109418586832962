/* eslint-disable react/display-name */
import type { NextComponentType } from 'next';
import React, { useMemo, Suspense } from 'react';
import dynamic from 'next/dynamic';
import type { AppProps } from 'next/app';
import { StepsProvider } from 'react-step-builder';

import '@/styles/globals.scss';
import '@/styles/App.scss';

const FrontContainer = dynamic(() => import('@/Layout/FrontContainer'));
import { AppProvider } from '@/components/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import '../assets/css/style.css';
import '../assets/css/responsive.css';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { SuspenseLoader } from '@/components/App/Loader';

const Meta = dynamic(() => import('@/Layout/FrontContainer/Components/Meta'));
interface AppComponentProps extends AppProps {
  pageProps: any;
  Component: NextComponentType & {
    auth?: boolean;
    meta: {
      title: string;
      description: string;
      keyword: string;
      siteUrl: string;
      locale: string;
      ogTitle: string;
      ogType: string;
      ogUrl: string;
      siteName: string;
      ogDescription: string;
    };
  };
}
export default function App({ Component, pageProps }: AppComponentProps) {
  const Layout = useMemo(
    () =>
      ({ children }: { children: React.ReactElement<any> }) =>
        <FrontContainer {...Component}>{children}</FrontContainer>,
    [Component],
  );

  const metaComponent = useMemo(
    () => (
      <Meta
        siteUrl={Component.meta?.siteUrl}
        locale={Component.meta?.locale}
        ogTitle={Component.meta?.ogTitle}
        ogType={Component.meta?.ogType}
        ogUrl={Component.meta?.ogUrl}
        siteName={Component.meta?.siteName}
        ogDescription={Component.meta?.ogDescription}
      />
    ),
    [Component.meta],
  );
  // if (!hydrate) {
  //   // Returns null on first render, so the client and server match
  //   return null;
  // }
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <ErrorBoundary>
        <StepsProvider>
          <AppProvider>
            <Layout>
              <>
                {metaComponent}
                <Component {...pageProps} />
              </>
            </Layout>
          </AppProvider>
        </StepsProvider>
      </ErrorBoundary>
    </Suspense>
  );
}
